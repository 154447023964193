import { pink, red } from "@material-ui/core/colors";

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: "30px",
    },
    grow: {
        flexGrow: 1,
    },
    main: {
       
    },
});

export default styles;